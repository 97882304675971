import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    { path: 'article', loadChildren: () => import('./_modules/article/article.module').then(m => m.ArticleModule) },
    { path: 'entries', loadChildren: () => import('./_modules/entries/entries.module').then(m => m.EntriesModule) },
    { path: 'motion', loadChildren: () => import('./_modules/tennis-animation/tennis-animation.module').then(m => m.TennisAnimationModule) },
    { path: ':id', redirectTo: 'article/:id', pathMatch: 'full' },
    { path: 'link-preview', loadChildren: () => import('./_modules/link-preview/link-preview.module').then(m => m.LinkPreviewModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabledBlocking'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
